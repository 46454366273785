import axios from "axios";

const token = JSON.parse(sessionStorage.getItem("token"));
const urlBase =
  process.env.NODE_ENV === "production"
    ? "https://tipsyswing.onrender.com"
    : "http://localhost:5000";
const API = axios.create({ baseURL: urlBase });



API.interceptors.request.use((req, res) => {
  if (token) {
    req.headers.Authorization = `bearer ${token}`;
  }
  return req;
});
export const changePw = (formData) => {
  const token = JSON.parse(sessionStorage.getItem("token"));
  API.interceptors.request.use((req, res) => {
    if (token) {
      req.headers.Authorization = `bearer ${token}`;
    }
    return req;
  });
  const response = API.post("user/changePw/", formData);
  return response;
};

export const expiredToken = () => API.post("user/checkToken", { token: token });
export const logIn = (formData) => API.post("user/login", formData);
export const register = (formData) => API.post("user/register", formData);
export const sendPwLink = (formData) => API.post("user/forgotPw", formData);
export const getTunes = async () => await API.post("tunes/");
export const getGigs = async () => await API.post("gigs/");
export const addTune = async (formData) => API.post("tunes/add", formData)
export const dropTune = async (formData) => API.post("tunes/drop", formData)
export const adminAddFile = async (formData) => API.post("admin/addfile", formData)
export const adminFindFile = async (formData) => await API.post("admin/findfile", formData)


